import InButtonSpinner from "@/components/Loader/InButtonSpinner";
import { SWW } from "@/config/toast";
import { sendOtpApi, verifyOtpApi } from "@/helpers/api/service/auth";
import { decodeToken, getUUID } from "@/utils/auth";
import { useEffect, useRef, useState } from "react";
// import { useTimer } from "react-timer-hook";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import FaPen from "@/components/SVG/Fontawesome/FaPen";
import { useDispatch, useSelector } from "react-redux";
import { nextOffCanvasView, selectLoginIntentPayloadEditable, selectLoginIntentPayloadMobile, selectLoginIntentPayloadSessionId, updateOffCanvasViewPayload } from "@/store/slices/offCanvas";
import { setUser } from "@/store/slices/auth";
import { isMobile } from "react-device-detect";


const VerifyOtp = () => {
  const [apiCall, setApiCall] = useState<boolean>(false);
  // const [resendOtpButtonDisabled, setResendOtpButtonDisabled] = useState<boolean>(true);
  // const [resendButtonVisible, setResendButtonVisible] = useState<boolean>(true);

  const router = useRouter();
  const dispatch = useDispatch();
  const mobile = useSelector(selectLoginIntentPayloadMobile);
  const sessionId = useSelector(selectLoginIntentPayloadSessionId);
  const editable = useSelector(selectLoginIntentPayloadEditable);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 60);
  // const timer = useTimer({ expiryTimestamp: time, onExpire: () => setResendOtpButtonDisabled(false), autoStart: false});

  const otpRef = useRef<any>();
  const acRef = useRef<AbortController>();

  const buildOtp: () => string = () => {
    return [...(otpRef.current?.children as any)].map((x: any) => x.value).join("");
  }

  const autoFillOtp = (otp: string) => {
    [...(otpRef.current?.children as any)].forEach((x: any, i: number) => x.value = otp[i]);
    verifyOtp();
  }

  const otpValidator = (event: any) => {
    if (event.keyCode === 13) {
      verifyOtp();
      event.preventDefault();
      return;
    }
    const inputs = otpRef.current?.children as any;
    const i = +event.target.id.split("-")[1];
    if (event.key === "Backspace") {
      inputs[i].value = "";
      if (i !== 0)
        inputs[i - 1].focus();
    }
  }

  const otpChangeValidator = (event: any) => {
    const inputs = otpRef.current?.children as any;
    const i = +event.target.id.split("-")[1];
    if (event.target.value === "") {
    } else {
      if (+event.target.value || +event.target.value === 0) {
        inputs[i].value = event.target.value;
        if (i !== inputs.length - 1)
          inputs[i + 1].focus();
        event.preventDefault();
      } else {
        inputs[i].value = "";
        event.preventDefault();
      }
    }
  }

  const verifyOtp = async () => {
    const otp = buildOtp();
    if (otp.length !== 4) {
      toast.error("Please enter 4 digits OTP");
      return;
    }
    setApiCall(true);
    const { data, meta } = await verifyOtpApi(sessionId, otp, getUUID());
    setApiCall(false);
    if (data.student_id) {
      const url = router.asPath.split("?")[0];
      const params = new URLSearchParams(router.asPath.split("?")[1]);
      params.delete("mobile");
      params.delete("otpGenerated");
      if (params.toString().length) {
        router.replace(`${url}?${params.toString()}`, undefined, { shallow: true });
      } else {
        router.replace(url, undefined, { shallow: true });
      }
      // firing everytime the tracking page to be handle using gtm
      window.dataLayer?.push({
        event: "login-otp-verified"
      });
      toast.success("OTP Verified");
      acRef.current?.abort("otp verified");
      acRef.current = undefined;
      dispatch(setUser(decodeToken()));
      dispatch(nextOffCanvasView({}));
      return;
    }
    toast.error(meta?.message ?? SWW);
  }

  // const reSendOtp = async () => {
  //   const { data } = await sendOtpApi(mobile);
  //   setResendButtonVisible(false);
  //   if (!data.session_id) {
  //     toast.error("Unable to generate OTP!");
  //     return;
  //   }
  //   toast.success("OTP Sent!");
  // }

  const changeMobile = () => {
    acRef.current?.abort("change mobile");
    acRef.current = undefined;
    dispatch(updateOffCanvasViewPayload({ LOGIN: { screen: "MOBILE" } }))
  }

  useEffect(() => {
    // timer.start();
    if(!isMobile) return;
    // setResendOtpButtonDisabled(true);
    const otpInputs: any[] = (otpRef.current?.children as any);
    otpInputs?.[0].focus();

    if ("OTPCredential" in window) { // enable this only for mobile/android
      acRef.current = new AbortController();
      navigator.credentials.get({
        //@ts-ignore
        otp: { transport: ["sms"] },
        signal: acRef.current.signal,
      }).then((otp: any) => {
        autoFillOtp(otp.code.toString());
      }).catch((_e) => {
        console.error(_e);
      });
    }
    return () => {
      acRef.current?.abort("otp cancelled");
      acRef.current = undefined;
    }
  }, []);


  return <>
    <div className="modal-body relative p-4 w-full">
      <div className="flex gap-4 mb-4 text-sm">
        <span className="font-neutral-600">OTP sent to <strong>+91 {mobile}</strong></span>
        {
          editable && <button className=" text-[#3F8DFD] font-bold" onClick={changeMobile}>
            Change
          </button>
        }
      </div>
      <div ref={otpRef} className="flex mt-8 space-x-2">
        <input className="input-single-digit form-control" type="tel" autoComplete="off" id="OTP-0" maxLength={1} onChange={otpChangeValidator} onKeyDown={otpValidator} onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)} />
        <input className="input-single-digit form-control" type="tel" autoComplete="off" id="OTP-1" maxLength={1} onChange={otpChangeValidator} onKeyDown={otpValidator} onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)} />
        <input className="input-single-digit form-control" type="tel" autoComplete="off" id="OTP-2" maxLength={1} onChange={otpChangeValidator} onKeyDown={otpValidator} onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)} />
        <input className="input-single-digit form-control" type="tel" autoComplete="off" id="OTP-3" maxLength={1} onChange={otpChangeValidator} onKeyDown={otpValidator} onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)} />
      </div>
    </div>
    {/* {timer.isRunning && <span className="font-normal text-sm mx-auto mb-2">Resend OTP in {timer.seconds} seconds...</span>}
    {resendButtonVisible && <button name="resend-otp" id="resend-otp" disabled={resendOtpButtonDisabled} onClick={reSendOtp} className={`font-medium text-md mx-auto mb-2 ${resendOtpButtonDisabled ? "text-gray-500" : "text-blue-500"}`}>{"Resend OTP"}</button>} */}
    <div className="mt-6 modal-footer flex w-full flex-shrink-0 flex-wrap px-4 rounded-b-md">
      <button name="verify-otp" id="verify-otp" type="button" onClick={verifyOtp} className="btn-primary w-full">
        Verify OTP
        <InButtonSpinner apiCall={apiCall} />
      </button>
    </div>
  </>
}

export default VerifyOtp;