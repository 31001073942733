import { verifyPinApi } from "@/helpers/api/service/auth";
import FaPen from "@/components/SVG/Fontawesome/FaPen";
import { useDispatch, useSelector } from "react-redux";
import Pin from "@/components/Pin";
import { selectLoginIntentPayloadMobile, updateOffCanvasViewPayload } from "@/store/slices/offCanvas";

const VerifyPin = () => {
  const dispatch = useDispatch();
  const mobile = useSelector(selectLoginIntentPayloadMobile);
  const changeMobile = () => {
    dispatch(updateOffCanvasViewPayload({ LOGIN: { screen: "MOBILE" } }));
  }

  return <>
    <div className="modal-body relative p-4 pb-8 w-full">
      <div className="flex justify-center items-center gap-4 mb-4">
        <span className="font-neutral-600">Login with Pin +91 {mobile}</span>
        <button className="btn-tertiary px-0" onClick={changeMobile}>
          <FaPen />
        </button>
      </div>
      <Pin callback={verifyPinApi} autoSubmit />
    </div>
  </>
}

export default VerifyPin;