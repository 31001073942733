import InButtonSpinner from "@/components/Loader/InButtonSpinner";
import { submitLeadForm } from "@/helpers/api/service";
import { sendOtpApi } from "@/helpers/api/service/auth";
import { selectStudentId } from "@/store/slices/auth";
import { selectLoginIntentPayloadMobile, selectLoginIntentPayloadUserParams, updateOffCanvasViewPayload } from "@/store/slices/offCanvas";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const SendOtp = () => {
  const [apiCall, setApiCall] = useState<boolean>(false);
  const dispatch = useDispatch();
  const mobile = useSelector(selectLoginIntentPayloadMobile);
  const userParams = useSelector(selectLoginIntentPayloadUserParams);
  const studentId = useSelector(selectStudentId);
  const mobileRef = useRef<HTMLInputElement>(null);

  const mobileValidator = (e: ChangeEvent<HTMLInputElement>) => {
    const validatedValue = e.target.value.replace(/[^0-9]/g, "");
    dispatch(updateOffCanvasViewPayload({ LOGIN: { mobile: validatedValue } }))
  }

  const keyDown = (event: any) => {
    if (event.keyCode === 13) {
      sendOtp();
      event.preventDefault();
    }
  }

  const sendOtp = async () => {
    if (mobile?.length !== 10) {
      toast.error("Please enter a valid mobile number.");
      return;
    }
    if (userParams) {
      submitLeadForm({
        ...userParams,
        mobile,
        studentId,
      });
    }
    setApiCall(true);
    const { data } = await sendOtpApi(mobile);
    setApiCall(false);
    if (!data.session_id) {
      toast.error("Unable to generate OTP!");
      return;
    }
    dispatch(updateOffCanvasViewPayload({ LOGIN: { mobile: mobile, sessionId: data.session_id, screen: "VERIFY_OTP", editable: true } }))
    toast.success("OTP Sent!");
  }

  const handleLoginWithPinClick = async () => {
    if (mobile?.length !== 10) {
      toast.error("Please enter a valid mobile number.");
      return;
    }
    dispatch(updateOffCanvasViewPayload({ LOGIN: { mobile: mobile, screen: "VERIFY_PIN" } }))
  }

  useEffect(() => {
    // without the delay the focus is not working another approach is to store input in state - will cause re render
    let timeoudId = setTimeout(() => {
      mobileRef.current?.focus();
    }, 500);

    return () => {
      clearTimeout(timeoudId);
    }
  }, [])

  return <>
    <div className="modal-body relative p-4 pt-2 w-full flex flex-col gap-2">
      <label className="font-bold">Mobile Number</label>
      <div className="flex items-center gap-2 relative">
        <span className="font-medium absolute text-sm left-4  border-gray-400 pr-2 text-body">+91</span>
        <input ref={mobileRef} autoFocus type="tel" value={mobile ?? ""} onChange={mobileValidator} maxLength={10} onKeyDown={keyDown}
          className="input font-medium pl-12 text-sm"
          placeholder="Enter your 10-digit mobile number" autoComplete="phone" />
      </div>
    </div>
    <div className="mt-6 modal-footer flex flex-col flex-shrink-0 flex-wrap justify-center w-full gap-2 px-4  rounded-b-md">
      <button name="send-otp" id="send-otp" type="button" onClick={sendOtp} className="btn-primary text-sm">
        Generate OTP
        <InButtonSpinner apiCall={apiCall} />
      </button>
      {/* <div className="grid grid-cols-5 text-center w-48">
        <hr className="my-3 mx-2 col-span-2" />
        <span className="relative left-0 right-0 m-auto">OR</span>
        <hr className="my-3 mx-2 col-span-2" />
      </div>
      <button id="pin-login" name="pin-login" className="btn btn-tertiary py-0" onClick={O}>
        Login with PIN
      </button> */}
    </div>
  </>
}

export default SendOtp;