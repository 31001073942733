import { SWW } from "@/config/toast";
import { getClassLanguage } from "@/helpers/api/service/auth";
import { setUser } from "@/store/slices/auth";
import { nextOffCanvasView, selectLoginIntentPayloadMobile } from "@/store/slices/offCanvas";
import { decodeToken, getUUID } from "@/utils/auth";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Pin = (props: {
  autoSubmit?: boolean;
  callback: Function;
}) => {
  const pinRef = useRef<any>();
  const mobile = useSelector(selectLoginIntentPayloadMobile);
  const dispatch = useDispatch();

  const buildPin: () => string = () => {
    return [...(pinRef.current?.children as any)].map((x: any) => x.value).join("");
  }

  const pinValidator = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      return;
    }
    const inputs = pinRef.current?.children as any;
    const i = +event.target.id.split("-")[1];
    if (event.key === "Backspace") {
      inputs[i].value = "";
      if (i !== 0)
        inputs[i - 1].focus();
    }
  }

  const pinChangeValidator = (event: any) => {
    const inputs = pinRef.current?.children as any;
    const i = +event.target.id.split("-")[1];
    if (event.target.value === "") {
    } else {
      if (+event.target.value || +event.target.value === 0) {
        inputs[i].value = event.target.value;
        if (i !== inputs.length - 1)
          inputs[i + 1].focus();
        event.preventDefault();
      } else {
        inputs[i].value = "";
        event.preventDefault();
      }
    }
    if (props.autoSubmit) {
      const pin = buildPin();
      if (pin?.length === 4) {
        submitPin()
      }
    }
  };

  const autoFillPin = (otp: string) => {
    [...(pinRef.current?.children as any)].forEach((x: any, i: number) => x.value = otp[i]);
  }

  const submitPin = async () => {
    const pin = buildPin();
    if (pin.length !== 4) {
      toast.error(`Please enter 4 digits PIN`);
      return;
    }
    const { meta, data } = await props.callback({ mobile, pin, udid: getUUID() })

    if (meta?.code === 200) {
      toast.success(meta.message || data.message);
      if (!props.autoSubmit) return;
      dispatch(setUser(decodeToken()));
      dispatch(nextOffCanvasView({}));
      return;
    }
    toast.error(meta?.message ?? SWW);
  }

  useEffect(() => {
    if (props.autoSubmit) return;
    getClassLanguage()
      .then((data) => {
        if (!data?.data?.pin_exist) return;
        autoFillPin(data.data.pin)
      })
  }, [props.autoSubmit])

  return <div className="flex flex-col gap-4 justify-center items-center">
    <div ref={pinRef} className="flex items-center justify-center space-x-2">
      <input autoFocus className="input-single-digit form-control" type="tel" autoComplete="off" id="PIN-0" maxLength={1} onChange={pinChangeValidator} onKeyDown={pinValidator} onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)} />
      <input className="input-single-digit form-control" type="tel" autoComplete="off" id="PIN-1" maxLength={1} onChange={pinChangeValidator} onKeyDown={pinValidator} onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)} />
      <input className="input-single-digit form-control" type="tel" autoComplete="off" id="PIN-2" maxLength={1} onChange={pinChangeValidator} onKeyDown={pinValidator} onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)} />
      <input className="input-single-digit form-control" type="tel" autoComplete="off" id="PIN-3" maxLength={1} onChange={pinChangeValidator} onKeyDown={pinValidator} onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)} />
    </div>
    {!props.autoSubmit && <button className="block btn btn-primary" onClick={submitPin}>Update Pin</button>}
  </div>
}

export default Pin;