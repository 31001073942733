import { LOGIN_SCREENS } from "@/config/enums";
import { useSelector } from "react-redux";
import SendOtp from "./SendOtp";
import VerifyOtp from "./VerifyOtp";
import VerifyPin from "./VerifyPin";
import { selectLoginIntentPayloadScreen } from "@/store/slices/offCanvas";

const LoginBody = () => {
  const loginScreen = useSelector(selectLoginIntentPayloadScreen);
  const title = LOGIN_SCREENS[loginScreen as keyof typeof LOGIN_SCREENS];

  return <>
    {title === "Login" && <SendOtp />}
    {title === "Enter OTP" && <VerifyOtp />}
    {title === "Verify PIN" && <VerifyPin />}
  </>
}

export default LoginBody;