import { BACKEND_API } from "@/config/constants";
import { apiClient } from "@/helpers/request";
import { getRefreshToken } from "@/utils/auth";

export const sendOtpApi = (mobile: string): Promise<{ data: { session_id: string }; meta: { message: string } }> => {
  return apiClient(BACKEND_API, "/v4/student/login", {
    method: "POST",
    headers: { version_code: "1500" },
    body: JSON.stringify({
      phone_number: mobile,
      is_web: "3",
    }),
  });
}

export const verifyOtpApi = (sessionId: string, otp: string, udid: string): Promise<{ data: { status?: "FAILURE", student_id?: number }; meta: { code: number; message: string } }> => {
  return apiClient(BACKEND_API, "/v4/student/verify", {
    method: "POST",
    headers: { version_code: "1500" },
    body: JSON.stringify({
      session_id: sessionId,
      udid,
      otp,
      web_login: true,
    }),
  });
}

export const verifyPinApi = ({ mobile, pin }: { mobile: string, pin: string }): Promise<{ data: { message: string; student_id?: number }; meta: { code: number; message: string } }> => {
  return apiClient(BACKEND_API, "/v4/student/verify-pin", {
    method: "POST",
    body: JSON.stringify({
      mobile,
      pin,
    }),
  });
}

export const updatePinApi = ({ pin, udid }: { pin: string, udid: string }): Promise<{ data: { message: string }; meta: { success: boolean; message: string } }> => {
  return apiClient(BACKEND_API, "/v1/student/store-pin", {
    method: "POST",
    headers: { version_code: "1500" },
    body: JSON.stringify({
      udid,
      pin,
      web_login: true,
      locale: "en",
    }),
  });
}

export const getClassLanguage = (): Promise<{ data: { pin: string, pin_exist: boolean; }; meta: { success: boolean; code: number } }> => {
  return apiClient(BACKEND_API, "/v1/student/get-class-language")
}

export const refreshToken = () => {
  return apiClient(BACKEND_API, `/v1/student/refresh-token`, {
    headers: {
      "x-auth-refresh-token": getRefreshToken(),
    },
  });
}

export const checkTruecallerLoginStatus = async (udid: string): Promise<{ status: string }> => {
  const { data } = await apiClient(BACKEND_API, `/v4/student/truecaller-web-login-status`, {
    query: { udid },
  });
  return data;
}